"use strict";

//
// Flatpickr
//

$(function() {

$(".DateArea").flatpickr({
	
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
});

});